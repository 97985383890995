import React, { useEffect } from "react";
import { Layout, SliderArtists } from '../components';
import { Helmet } from "react-helmet";

// markup
const Artists = ({ location }) => {

	useEffect(() => {
		return () => {
			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		}
	}, []);

	return (
		<Layout location={location}>
			<Helmet>
				<link href="/assets/css/artists.css" rel="stylesheet" type="text/css" />
				<title>Ephemeral/Ethernal - Artists</title>
			</Helmet>

			<section data-name="layout" id={'artists_page'}>
				<div className={'page-container'}>
					<div className={'artists-container'}>

						<div className={'container-fluid col-10 col-md-12 col-lg-10 d-flex justify-content-between align-items-end'}>
							<div className={'slider-title'}>Artists</div>
						</div>

						<SliderArtists />

					</div>
				</div>
			</section>
		</Layout >
	)
}


/* const ArtistsOLD = ({ location }) => {

	const [artistsLoader, setArtistsLoader] = useState(null);
	const [artistsList, setArtistsList] = useState([]);

	const [artistsOrderBy, setArtistsOrderBy] = useState('n');
	const [artistsOrderDir, setArtistsOrderDir] = useState('asc');

	const [artistsFilterList, setArtistsFilterList] = useState(null);
	const [dropsFilterList, setDropsFilterList] = useState(null);

	const [artworksFilterDrop, setArtworksFilterDrop] = useState(null);
	const [artworksFilterArtist, setArtworksFilterArtist] = useState(null);


	const sortList = [
		{ value: 'd_desc', label: 'Most recent first' },
		{ value: 'd_asc', label: 'Oldest first' },
		{ value: 'n_asc', label: 'Name Ascending' },
		{ value: 'n_desc', label: 'Name Descending' },
	];



	useEffect(() => {
		getArtists(setArtistsLoader, { offset: 0, limit: 12, order_by: artistsOrderBy, order_direction: artistsOrderDir });

		getArtists(setArtistsFilterList, { limit: -1, order_by: 'n', order_direction: 'asc' });
		getDrops(setDropsFilterList, { limit: -1, order_by: 'dl', order_direction: 'asc' });

		return () => {
			document.querySelector("html").classList.remove("no-scroll");
			document.querySelector("body").classList.remove("no-scroll");
		}

	}, []);

	useEffect(() => {
		if (artistsLoader == null) {
			return;
		}

		setArtistsList(artistsList.concat(artistsLoader.i));
	}, [artistsLoader]);

	const onLoadMoreArtists = () => {
		getArtists(setArtistsLoader, { offset: artistsList.length, limit: 12, order_by: artistsOrderBy, order_direction: artistsOrderDir });
	}

	const onToggleFilters = (evt) => {
		console.log(evt.target.checked);

		if (evt.target.checked) {
			document.querySelector("html").classList.add("no-scroll")
			document.querySelector("body").classList.add("no-scroll")
		} else {
			document.querySelector("html").classList.remove("no-scroll")
			document.querySelector("body").classList.remove("no-scroll")
		}
	}

	const onChangeFilterDrop = (value) => {
		console.log('Drop selected: ' + value);
		let real_value = value == '-' ? null : value;
		setArtworksFilterDrop(real_value);
		setArtistsFilterList(null);
		getArtists(setArtistsFilterList, { limit: -1, order_by: 'n', order_direction: 'asc', filters: { drop: real_value } });
	};

	const onChangeFilterArtist = (value) => {
		console.log('Artist selected: ' + value);
		let real_value = value == '-' ? null : value;
		setArtworksFilterArtist(real_value);
		setDropsFilterList(null);
		getDrops(setDropsFilterList, { limit: -1, order_by: 'dl', order_direction: 'asc', filters: { artist: real_value } });
	};

	const onChangeFiltersSort = (value) => {
		let value_parts = value.split('_')
		setArtistsOrderBy(value_parts[0]);
		setArtistsOrderDir(value_parts[1]);
	}

	const onSubmitFilters = () => {
		setArtistsLoader(null);
		setArtistsList([]);
		getArtists(setArtistsLoader, {
			offset: 0, limit: 12, order_by: artistsOrderBy, order_direction: artistsOrderDir, filters: {
				drop: artworksFilterDrop,
				artist: artworksFilterArtist,
			}
		});
	}

	return (
		<Layout location={location}>
			<Helmet>
				<link href="/assets/css/artists.css" rel="stylesheet" type="text/css" />
				<title>Ephemeral/Ethernal - Artists</title>
			</Helmet>


			<section data-name="layout" id={'artists_page'}>
				<div className={'page-container'}>

					<FiltersNav location={location} items={artistsLoader == null ? '...' : artistsLoader.t} />

					<div className={'artists-bg p-0'}></div>

					<div className={'artists-container container-fluid d-flex align-items-center flex-column'}>

						<div className={'page-title col-12 col-md-11'}>
							<h1 className="artists-title pb-4">Art<br />ists</h1>
						</div>

						<div className={'col-11 cards-container'}>

							{artistsLoader == null ? (
								<>
									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}></div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xl-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>

									<div className={'content-placeholder my-4 col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-xxl-block'}>
										<div className={'card w-100'}>
											<div className={'dummy-img gradient'}>&nbsp;</div>
											<div className={'dummy-title gradient'}>&nbsp;</div>
											<div className={'dummy-description gradient'}>&nbsp;</div>
											<div className={'dummy-artist'}>
												<div className={'circle gradient'}>&nbsp;</div>
												<div className={'artist-name gradient'}>&nbsp;</div>
											</div>
										</div>
									</div>
								</>
							) : (
								<>
									{artistsLoader.i ? (

										artistsList.map((artist) => {
											let random_nft_index = Math.floor(Math.random() * artist.nft_assets.length);
											return (

												<>

													<div className={'card my-4 col-12 col-md-6 col-xl-4 col-xxl-3'}>
														<div className={'artist-img'}>
															<Link to={'/artists/detail/?' + artist.slug}>
																<div className={'open-hover-fx'}>Open</div>

																{artist.highlight_square_paths ? (
																	<ResponsiveImage
																		initialSrc={artist.highlight_square_paths['275']}
																		initialSrcSet={`${artist.highlight_square_paths['275']} 1x, ${artist.highlight_square_paths['550']} 2x`}
																		alt={artist.name}
																		sources={[
																			{
																				srcset: [
																					{
																						src: artist.highlight_square_paths['275_webp'],
																						xd: "1x",
																					},
																					{
																						src: artist.highlight_square_paths['550_webp'],
																						xd: "2x",
																					}
																				],
																			}
																		]}
																	/>
																) : (
																	artist.nft_assets.length ? (
																		<ResponsiveImage
																			initialSrc={artist.nft_assets[random_nft_index].image_preview_url}
																			initialSrcSet={`${artist.nft_assets[random_nft_index].image_preview_url} 1x, ${artist.nft_assets[random_nft_index].image_url} 2x`}
																			alt={"Artist NFT"}
																		/>
																	) : (
																		<div style={{ width: 275, height: 266, backgroundColor: 'var(--brand-color2)', color: '#fff', fontFamily: 'Primary-Regular', fontSize: '2.25rem', lineHeight: '1.1em', padding: '1em', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>{artist.name}</div>
																	)
																)}

															</Link>

														</div>

														<div className={'artist-name'}>
															<Link to={'/artists/detail/?' + artist.slug}>
																{artist.name}
															</Link>
														</div>

														<div className={'artist-nfts'}>
															<div>{artist.nft_count}&nbsp;NFT{artist.nft_count !== 1 ? 's' : ''}</div>
														</div>
													</div>
												</>

											);

										})

									) : (
										<div className={'container-fluid text-center my-5'}>Unable to load assets from OpenSea</div>
									)
									}
								</>
							)}


						</div>

						<div className={'load-more my-4 col-12 col-md-11 d-flex justify-content-center' + ((artistsLoader == null) || (artistsLoader != null && artistsList.length === artistsLoader.t) ? ' d-none' : '')}>
							<button className={'btn btn--secondary'} onClick={onLoadMoreArtists}>
								Load More
								<div className={'btn-arrow'}></div>
							</button>
							<div className="load-more-count">
								{artistsLoader == null ? '...' : artistsLoader.t - artistsList.length}
							</div>
						</div>

					</div>
				</div>
			</section>


			<input type="checkbox" id={'toggle_filters_modal'} onChange={onToggleFilters} autoComplete="off" hidden />
			<section id={'filters_artists_page'} className={'filters-artists-page'}>
				<div className={'container-fluid'}>

					<div className={'title-container d-flex justify-content-center'}>
						<div className={'title col-10 my-3 my-5'}>Filter by</div>
					</div>

					<div className={'d-flex justify-content-center'}>

						<div className={'filters-dropdown col-10 d-flex justify-content-between flex-column flex-md-row flex-wrap'}>
							<div className={'my-2'}>
								<div className={'my-2'}>
									Drop
								</div>

								<Select2
									data={dropsFilterList == null ? [{ value: '-', label: 'All drops' }] : [{ value: '-', label: 'All drops' }].concat(dropsFilterList.i.map((drop) => {
										return {
											value: drop.slug,
											label: 'Drop #' + drop.number + ' - ' + drop.name,
										};
									}))}
									value={'-'}
									update={onChangeFilterDrop}
									disabled={dropsFilterList == null ? true : false}
								/>
							</div>

							<div className={'my-2'}>
								<div className={'my-2'}>
									Artist
								</div>

								<Select2 data={artistsFilterList == null ? [{ value: '-', label: 'All artists' }] : [{ value: '-', label: 'All artists' }].concat(artistsFilterList.i.map((artist) => {
									return {
										value: artist.slug,
										label: artist.name,
										component: (inp) => <span>{artist.avatar_paths ? <img src={artist.avatar_paths[24]} /> : ''} {artist.name}</span>
									}
								}))}
									value={'-'}
									update={onChangeFilterArtist}
									disabled={artistsFilterList == null ? true : false}
									placeholder={artistsFilterList == null ? 'Loading artists...' : 'Choose an artist'} />
							</div>


							<div className={'my-2'}>
								<div className={'my-2'}>
									Sort
								</div>

								<Select2 data={sortList}
									value={'d_desc'}
									update={onChangeFiltersSort}
								/>

							</div>
						</div>

					</div>

					<div className={'filter-btn-container d-flex justify-content-center'}>
						<div className={'col-10 d-flex justify-content-between justify-content-lg-end'}>

							<div className={'cancel-btn'}>
								<label htmlFor={'toggle_filters_modal'} className={'btn btn--secondary'}>
									Cancel
								</label>
							</div>
							<div className={'submit-btn'}>
								<label className={'btn btn--secondary'} onClick={onSubmitFilters} htmlFor={'toggle_filters_modal'}>
									<div className={'me-2'}>
										<img src={'/assets/img/icons/filters_icon_fff.svg'} alt={'alt-icon'} />
									</div>
									Filter
								</label>
							</div>
						</div>

					</div>

				</div>
			</section>


		</Layout >
	)
} */

export default Artists
